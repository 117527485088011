import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    postRequestFormData,
    postRequestadFormData,
} from "../coreFIles/helper";
// user login
export const LoginAction = (data) => {
  return postRequest("userLogin", data).then((res) => {
    return res.data;
  });
};

// user Profile
export const getUserProfileAction = (data) => {
  return postRequest("getUserProfile", data).then((res) => {
    return res.data;
  });
};

export const UpdateUserProfileAction = (data) => {
  return postRequest("UpdateUserProfile", data).then((res) => {
    return res.data;
  });
};

export const UpdateUserPasswordAction = (data) => {
  return postRequest("UpdateUserPassword", data).then((res) => {
    return res.data;
  });
};

export const getindustrylistAction = (data) => {
  return postRequest("getindustrylist", data).then((res) => {
    return res.data;
  });
};

export const getMonthlySpendlistAction = (data) => {
  return postRequest("getMonthlySpendlist", data).then((res) => {
    return res.data;
  });
};

export const getSearchSuburbAction = (data) => {
  return postRequest("getSearchSuburb", data).then((res) => {
    return res.data;
  });
};

export const RegisterUserAction = (data) => {
  return postRequest("RegisterNewUser", data).then((res) => {
    return res.data;
  });
};

// Get parent cats
export const getParentCatlistAction = (data) => {
  return postRequest("getParentCatlist", data).then((res) => {
    return res.data;
  });
};

export const getChildCatlistAction = (data) => {
  return postRequest("getChildCatlist", data).then((res) => {
    return res.data;
  });
};
export const getAllProductsListAction = (data) => {
  return postRequest("getAllProductsList", data).then((res) => {
    return res.data;
  });
};
export const getTotalAllProductsAction = (data) => {
  return postRequest("getTotalAllProducts", data).then((res) => {
    return res.data;
  });
};
export const getAllProductsCatsAction = (data) => {
  return postRequest("getAllProductsCats", data).then((res) => {
    return res.data;
  });
};

export const getmasterFilterAction = (data) => {
  return postRequest("getmasterFilter", data).then((res) => {
    return res.data;
  });
};

export const getFilterCountListAction = (data) => {
  return postRequest("getFilterCountList", data).then((res) => {
    return res.data;
  });
};
export const getUserPendingQuotesAction = (data) => {
  return postRequest("getUserPendingQuotes", data).then((res) => {
    return res.data;
  });
};
export const CreateNewQuoteAction = (data) => {
  return postRequest("CreateNewQuote", data).then((res) => {
    return res.data;
  });
};
export const addToCartProductAction = (data) => {
  return postRequest("addToCartProduct", data).then((res) => {
    return res.data;
  });
};
export const UpdateCartProductAction = (data) => {
  return postRequest("UpdateCartProduct", data).then((res) => {
    return res.data;
  });
};
export const removeCartProductAction = (data) => {
  return postRequest("removeCartProduct", data).then((res) => {
    return res.data;
  });
};

export const getCartOrderSummeryAction = (data) => {
  return postRequest("getCartOrderSummery", data).then((res) => {
    return res.data;
  });
};
export const deleteUseraddressAction = (data) => {
  return postRequest("deleteUseraddress", data).then((res) => {
    return res.data;
  });
};
export const AddUserNewAddressAction = (data) => {
  return postRequest("AddUserNewAddress", data).then((res) => {
    return res.data;
  });
};

export const updateShipToAddressAction = (data) => {
  return postRequest("updateShipToAddress", data).then((res) => {
    return res.data;
  });
};
export const GetAddressDetailsAction = (data) => {
  return postRequest("GetAddressDetails", data).then((res) => {
    return res.data;
  });
};
export const UpdateAddressAction = (data) => {
  return postRequest("UpdateAddress", data).then((res) => {
    return res.data;
  });
};
export const PlaceNewOrderAction = (data) => {
  return postRequest("PlaceNewOrder", data).then((res) => {
    return res.data;
  });
};

export const getUserOrdersListAction = (data) => {
  return postRequest("getUserOrdersList", data).then((res) => {
    return res.data;
  });
};

export const getUserOrdersDetailsAction = (data) => {
  return postRequest("getUserOrdersDetails", data).then((res) => {
    return res.data;
  });
};

export const CreateContactOnXEROAction = (data) => {
  return postRequest("CreateContactOnXERO", data).then((res) => {
    return res.data;
  });
};

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Pagination from '../directives/Pagination';
import Cookies from 'js-cookie'
import Modal from 'react-modal';
import { InputMask } from '@react-input/mask';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getParentCatlistAction, getmasterFilterAction, getChildCatlistAction, getAllProductsListAction, getTotalAllProductsAction, getFilterCountListAction, getUserProfileAction, getSearchSuburbAction, UpdateUserProfileAction, UpdateUserPasswordAction, getUserPendingQuotesAction, CreateNewQuoteAction, addToCartProductAction, removeCartProductAction, UpdateCartProductAction, getCartOrderSummeryAction, deleteUseraddressAction, AddUserNewAddressAction, updateShipToAddressAction, GetAddressDetailsAction, UpdateAddressAction,PlaceNewOrderAction, getUserOrdersListAction, getUserOrdersDetailsAction, getAllProductsCatsAction, CreateContactOnXEROAction  } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));
const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const Products = () => {
	const [homeSliders, sethomeSliders]	= useState({});
	const [pcategories, setpcategories]	= useState([]);
	const [childcats, setchildcats]		= useState([]);
	const [childcats2, setchildcats2]		= useState([]);
	const [childcats3, setchildcats3]		= useState([]);
	const [childcats4, setchildcats4]		= useState([]);
	const [childcats5, setchildcats5]		= useState([]);
	const [childcats6, setchildcats6]		= useState([]);
	const [pCatData, setPcat]				= useState({});
	const [CCatData1, setCcat1]				= useState({});
	const [CCatData2, setCcat2]				= useState({});
	const [CCatData3, setCcat3]				= useState({});
	const [CCatData4, setCcat4]				= useState({});
	const [CCatData5, setCcat5]				= useState({});
	const [CCatData6, setCcat6]				= useState({});
	const [finishData, setfinishData]		= useState({});
	const [materialData, setmaterialData]	= useState({});
	const [threadData, setthreadData]		= useState({});
	const [paintData, setpaintData]			= useState({});
	const [drivetypeData, setdrivetypeData]	= useState({});	
	const [producttypecount, setproducttypecount] = useState(0);	
	const [pCatId, setPcatId]			= useState(0);
	const [child1, setchild1]			= useState(0);
	const [child2, setchild2]			= useState(0);
	const [child3, setchild3]			= useState(0);
	const [child4, setchild4]			= useState(0);
	const [child5, setchild5]			= useState(0);
	const [child6, setchild6]			= useState(0);
	const [finish, setfinish]			= useState(0);
	const [material, setmaterial]		= useState(0);
	const [thread, setthread]			= useState(0);
	const [paint, setpaint]				= useState(0);
	const [drivetype, setdrivetype]		= useState(0);
	const [Allproducts, setAllproducts]	= useState([]);
	const [filterscount, setfilterscount]	= useState({});
	const [masterfilters, setmasterfilters]	= useState([]);
	const [filterChilds, setfilterChilds]	= useState([]);
	const [ProductsAllCats, setAllProductsCats]	= useState([]);
	const [page, setPage] 					= useState(1); 
	const [totalPages, settotalPages] 		= useState(0);
	const [totalRecords, settotalRecords] 	= useState(0);
	const [FilterCatID, setFilterCatID] 	= useState(0);
	const [pcatcount, setpcatcount] 		= useState(0);
	const [productName, setProductName] 	= useState('');
	const [validationError, setvalidationError] = useState({});
	const [addresseditvalidationError, setaddresseditvalidationError] = useState({});
	const [addressvalidationError, setaddressvalidationError] = useState({});
	const [pwvalidationError, setpwvalidationError] = useState({});
	const [UserProfile, setUserProfile] 	= useState({});
	const [UserAddress, setUserAddress] 	= useState([]);	
	const [defaultAddress, setDefaultAddress] 	= useState({});
	const [defaultAddress2, setDefaultAddress2] 	= useState({});
	const [siteDeliveryAdd, setsiteDeliveryAdd] 	= useState({});
	const [showNewAddress, setshowNewAddress] = useState(1);
	const [suburb, setsuburb] 				= useState('');
	const [postcode, setpostcode] 			= useState('');
	const [SuburbsearchResults, setSuburbsearchResults] 	= useState([]);
	const [PendingQuotes, setPendingQuotes] 	= useState([]);
	const [spinLoader, setSpinLoader] = useState(0);
	const [spinpwLoader, setSpinpwLoader] = useState(0);
	const [quoteLoder, setquoteLoder] = useState(0);
	const [form, setForm] 	= useState({ email:loginData?.email, curpassword: '', password: '', cpassword: '' });
	const [QuoteselectedIndex, setQuoteselectedIndex] = useState(0);	
	const [selectedQuoteId, setSelectedQuoteId] = useState(null);
	const [expandedProductRows, setexpandedProductRows] = useState({});
	const [totals, setTotals] = useState({});
	const [showtotals, setShowTotals] = useState(0);
	const [addmodalopen, setAddModalopen] = useState(0);
	const [showAddressEditForm, setshowAddressEditForm] = useState(0);
	const [AddressEditForm, setAddressEditForm] 	= useState({});
	const [addsuburb, setaddsuburb] 				= useState('');
	const [addpostcode, setaddpostcode] 			= useState('');
	const [addeditsuburb, setaddeditsuburb] 				= useState('');
	const [addeditpostcode, setaddeditpostcode] 			= useState('');
	const [newAddressform, setnewAddressForm] 	= useState({ user_id:loginData?.id,first_name:'',last_name:'',phone:'', newaddress_1: '', newstate: '', newpostcode: '', newsuburb:'', shipto:1 });
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [OrdersList, setOrdersList] 	= useState({pending:[],processing:[],backorders:[],completed:[]});
	const [OrdersListLoader, setOrdersListLoader] 	= useState(0);
	const [odrmodalopen, setodrmodalopen] 	= useState(0);
	const [orderdetails, setorderdetails] 	= useState({});
	const [visible, setVisible] 			= useState(config.visible);	
	const [PoOrder, setPoOrder] 			= useState('');	
	const [newOrderCartID, setnewOrderCartID] 			= useState(null);	
	const [PoOrderPopup, ShowPoOrderPopup] 	= useState(0);
	
	 
	useEffect(() => {
		if (!loginData || loginData == '') {
		window.location.href = `login`;
	  }	
	  geParentCategoriesAPI();
	  getmasterFilterAPI();
	  getUserProfileAPI();
	  getUserPendingQuotesAPI();
	  getUserOrdersListAPI();
	},[])	
	// Fetch products when page or filter changes
	useEffect(() => {	  
		getAllProductsAPI();
		getTotalAllProductsAPI();
	}, [page, pCatId, child1, child2, child3, child4, child5, child6, productName,finish,material,thread,paint,drivetype]);	
	useEffect(() => {   
		if (PendingQuotes.length > 0 && selectedQuoteId == null) {
		  setQuoteselectedIndex(PendingQuotes.length + 2);
		  setSelectedQuoteId(PendingQuotes[PendingQuotes.length - 1].id);
		  updateordersummery(PendingQuotes[PendingQuotes.length - 1].id);
		  setVisible(config.visible);
		}
	}, [PendingQuotes]);
	useEffect(() => {    
		const defaultAddr 	= UserAddress.find(address => address.shipto === 0 && address.set_default === 1);
		const defaultAddr2 	= UserAddress.find(address => address.shipto === 1 && address.set_default === 1);
		const siteAddress 	= UserAddress.find(address => address.shipto === 2 && address.set_default === 1);
		if (defaultAddr) {
		  setDefaultAddress(defaultAddr);
		}
		if (defaultAddr2) {
		  setDefaultAddress2(defaultAddr2);
		}
		if (siteAddress) {
		  setsiteDeliveryAdd(siteAddress);
		}
	  }, [UserAddress]);
	useEffect(() => { 
		if(productName !=''){
			getAllProductsCatsAPI();
			} 
		else
		{
		 setAllProductsCats([]);		
		}	 		
	  }, [productName]);  
	const toggleProductRow = (productId) => {
		setexpandedProductRows((prevState) => ({
		  ...prevState,
		  [productId]: !prevState[productId],
		}));
	  };
	const CreateNewQuote = async () => {
		let res = await CreateNewQuoteAction();
		if (res.success) {
			toast.success(res.msg);
			getUserNewPendingQuotesAPI();			
		}
		else{
			toast.error(res.msg);
		}
				
	}
	const handleTabSelect = (index) => {
		
		if(index==1 || index==2 ){
			setQuoteselectedIndex(index);					
		}else{
			if(index == 0){
				console.log(PendingQuotes.length);
				if (PendingQuotes.length > 0) {
				setQuoteselectedIndex(PendingQuotes.length + 2);		
				setSelectedQuoteId(PendingQuotes[PendingQuotes.length - 1].id); 
				updateordersummery(PendingQuotes[PendingQuotes.length - 1].id);
				setVisible(config.visible);
				}
			}else{							
				if(index <= PendingQuotes.length + 2){
					setQuoteselectedIndex(index);		
					setSelectedQuoteId(PendingQuotes[index-3].id); 
					updateordersummery(PendingQuotes[index-3].id);
					setVisible(config.visible);
				}
			}
			
		}
		setShowTotals(0);		
	};  
	const inputSearchHandler = async (e) => {
		const { name, value } = e.target
		setProductName(value);		
	}
	const clearsearchtext	= async () => {		  
		setProductName('');
		setAllProductsCats([]);			
	}
	const clearallsearch	= async () => {	
		 window.location.reload();  
	}
	const inputHandler = async (e) => {
		const { name, value } = e.target
		//console.log(value);
		setUserProfile((old) => {
			return { ...old, [name]: value }
		})
	}
	const inputPwHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	const handleKeyDown = (e) => {
		//console.log(e.key);
        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
        }
    };  
	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			setpostcode('');
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}
		catch (error) {
			console.error(error);
		}
	};	   
	const handleSelectSuburb = (row) => {	   
		setsuburb(row.suburb);
		setpostcode(row.postcode);
		setUserProfile((old) => {
			return { ...old, 'city': row.suburb };
		});
		setUserProfile((old) => {
			return { ...old, 'postcode': row.postcode };
		});
		setSuburbsearchResults({});
	};			
	const getUserProfileAPI = async () => {
		let res = await getUserProfileAction({user_id:loginData?.id});
		if (res.success) {
			let data = res.data;
			let addresses = res.addresses;
			setsuburb(data.city);
			setpostcode(data.postcode);			
			setUserProfile(data);			
			setUserAddress(addresses);						
		}
		else
		{
			setUserProfile([]);	
			setUserAddress([]);				
		}
	}
	const getUserPendingQuotesAPI = async () => {
		setquoteLoder(1);
		let res = await getUserPendingQuotesAction();
		if (res.success) {
			let data = res.data;
			setPendingQuotes(data);		
			setquoteLoder(0);	
		}
		else
		{
			setPendingQuotes([]);
			setquoteLoder(0);					
		}
	}
	const getUserNewPendingQuotesAPI = async () => {
		setquoteLoder(1);
		let res = await getUserPendingQuotesAction();
		if (res.success) {
			let data = res.data;
			setPendingQuotes(data);		
			setquoteLoder(0);	
			if (data.length > 0) {
			  setQuoteselectedIndex(data.length + 2);
			  setSelectedQuoteId(data[data.length - 1].id); 
			  updateordersummery(data[data.length - 1].id);
			  setVisible(config.visible);
			}
		}
		else
		{
			setPendingQuotes([]);
			setquoteLoder(0);					
		}
	}
	function validate() {	   
		let first_nameError = "";
		let last_nameError = "";		
		let phoneError = "";		
		let addressError = "";
		let suburbError = "";
		let stateError = "";
		let postcodeError = "";		
		
		if (UserProfile.first_name === '') {
		  first_nameError = "First name is required."
		}
		if (UserProfile.last_name === '') {
			last_nameError = "Last name is required."
		}		
		if (UserProfile.phone === '') {
			phoneError = "Phone is required."
		}
		if (UserProfile.address_1 === '') {
			addressError = "Address is required."
		}
		if (UserProfile.city === '') {
			suburbError = "Suburb is required."
		}
		if (UserProfile.state === '') {
			stateError = "State is required."
		}
		if (UserProfile.postcode === '') {
			postcodeError = "Postcode is required."
		}				
		if (first_nameError || last_nameError || phoneError || addressError|| suburbError|| stateError|| postcodeError) {
			setvalidationError({
			  first_nameError, last_nameError, phoneError, addressError, suburbError, stateError, postcodeError	})
			return false
		} else {
			setvalidationError({ first_nameError, last_nameError, phoneError, addressError, suburbError, stateError, postcodeError })
			return true
		}
	}
	const UpdateProfile = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {
		}else {
			setSpinLoader(1);
			let res = await UpdateUserProfileAction(UserProfile);
			if (res.success) {
				toast.success(res.msg);
				getUserProfileAPI();
				setSpinLoader(0);				
			} else {
				toast.error(res.msg);
				setSpinLoader(0);
			}
		}
	}
	function validatepwd() {	   
		let curpasswordError = "";
		let passwordError = "";		
		let cpasswordError = "";	
		
		if (form.curpassword === '') {
		  curpasswordError = "Current password is required."
		}
		if (form.password === '') {
			passwordError = "New password is required."
		}		
		if (form.cpassword === '') {
			cpasswordError = "Confirm password required."
		}
		
		if(form.password !='')
		{
			if (form.cpassword != form.password) {
				cpasswordError = "Confirm password required."
			}
		}
			
		if (curpasswordError || passwordError || cpasswordError) {
			setpwvalidationError({ curpasswordError, passwordError, cpasswordError	})
			return false
		} else {
			setpwvalidationError({ curpasswordError, passwordError, cpasswordError })
			return true
		}
	}	
	const UpdateUserPassword = async (e) => {
		e.preventDefault()
		const isValid = validatepwd();
		if (!isValid) {
		}else {
			setSpinpwLoader(1);
			let res = await UpdateUserPasswordAction(form);
			if (res.success) {
				toast.success(res.msg);				
				setSpinpwLoader(0);	
				setForm({ email:loginData?.email, curpassword: '', password: '', cpassword: '' });
						
			} else {
				toast.error(res.msg);
				setSpinpwLoader(0);
			}
		}
	}
	const geParentCategoriesAPI = async () => {
		let res = await getParentCatlistAction();
		if (res.success) {
			let data = res.data;			
			setpcategories(data);
			setproducttypecount(data.length);	
			setpcatcount(data.length);
		}else{
			setpcategories([]);	
			setproducttypecount(0);
		}
	}
	const getmasterFilterAPI = async () => {
		let res = await getmasterFilterAction();
		if (res.success) {
			let data = res.data;			
			setmasterfilters(data);			
		}else{
			setmasterfilters([]);				
		}
	}	
	//get products 
	const getAllProductsAPI = async () => {
		let res = await getAllProductsListAction({page:page,p_cat :pCatId, child1:child1, child2:child2, child3:child3, child4:child4, child5:child5, child6:child6, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});
		if (res.success) {
			let data = res.data;			
			setAllproducts(data);
					
		}else{
			setAllproducts([]);	
			
		}
	}
	const getTotalAllProductsAPI = async () => {
		settotalPages(0);
		settotalRecords(0);
		let res = await getTotalAllProductsAction({p_cat :pCatId, child1:child1, child2:child2, child3:child3, child4:child4, child5:child5, child6:child6, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});		
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords); 
		}else{
			settotalPages(0);
			settotalRecords(0);	
		}
	}
	const getAllProductsCatsAPI = async () => {		
		let res = await getAllProductsCatsAction({p_cat :pCatId, child1:child1, child2:child2, child3:child3, child4:child4, child5:child5, child6:child6, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});		
        if (res.success) {
            setAllProductsCats(res.data);
		}else{
			setAllProductsCats([]);	
		}
	}
	const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getAllProductsListAction({page:offset,p_cat :pCatId, child1:child1, child2:child2, child3:child3, child4:child4, child5:child5, child6:child6, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});
		if (res.success) {
			let data = res.data;			
			setAllproducts(data);					
		}else{
			setAllproducts([]);				
		}
	}
	// set filters
	const SetParentCat = async (item) => {
		setPcat(item)
		setPcatId(item.id)	
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);	
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			let data = res.data;			
			setchildcats(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats([]);	
			setproducttypecount(0);
		}	
	}
	const Setchild1 = async (item) => {
		setCcat1(item)
		setchild1(item.id)	
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);		
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0){
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}else{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats2(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats2([]);	
			setproducttypecount(0);
		}
	}
	const Setchild2 = async (item) => {
		setCcat2(item)
		setchild2(item.id)	
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);				
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0){
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}else{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats3(data);
			setproducttypecount(data.length);	
		}else{
			setchildcats3([]);	
			setproducttypecount(0);
		}
	}
	const Setchild3 = async (item) => {
		setCcat3(item)
		setchild3(item.id)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);	
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0){
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}else{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats4(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats4([]);
			setproducttypecount(0);	
		}
	}
	const Setchild4 = async (item) => {
		setCcat4(item)
		setchild4(item.id)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);			
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0){
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}else{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats5(data);
			setproducttypecount(data.length);	
		}else{
			setchildcats5([]);	
			setproducttypecount(0);
		}
	}
	const Setchild5 = async (item) => {
		setCcat5(item)
		setchild5(item.id)	
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);			
		let res = await getChildCatlistAction({p_cat:item.id});
		if (res.success) {
			if(item.child_count == 0){
				setFilterCatID(item.id);
				getFilterAPI(item.id);
			}else{
				setfilterscount({});
				setfilterChilds([])
			}
			let data = res.data;			
			setchildcats6(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats6([]);	
			setproducttypecount(0);
		}
	}
	const Setchild6 = async (item) => {
		setCcat6(item)
		setchild6(item.id)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);		
		if(item.child_count == 0){
			setFilterCatID(item.id);
			getFilterAPI(item.id);
		}else{
			setfilterscount({});
			setfilterChilds([])
		}		
	}
	const SetFilterfltr = async (item,filterType) => {
		 if (filterType == config.finish) {
			setfinishData(item);
			setfinish(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,item.child_id,material,thread,paint,drivetype);
		  } else if (filterType == config.material) {
			setmaterialData(item);
			setmaterial(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,item.child_id,thread,paint,drivetype);
		  } else if (filterType == config.thread) {
			setthreadData(item);
			setthread(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,item.child_id,paint,drivetype);
		  } else if (filterType == config.paint) {
			setpaintData(item);
			setpaint(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,item.child_id,drivetype);
		  } else if (filterType == config.drive) {
			setdrivetypeData(item);
			setdrivetype(item.child_id)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,paint,item.child_id);
		  }		
	}
	const getFilterAPI = async (id) => {		
		let res = await getFilterCountListAction({cat_id :id, productName:productName, finish:finish, material:material, thread:thread, paint:paint, drivetype:drivetype});
		if (res.success) {
			let data = res.data;			
			let filtersdata = res.filtersdata;			
			setfilterscount(data);
			setfilterChilds(filtersdata)					
		}else{
			setfilterscount({});
			setfilterChilds([])			
		}
	}
	const getFilterAPIOnSelect = async (id,pname,fin,mat,th,pnt,driv) => {		
		let res = await getFilterCountListAction({cat_id :id, productName:pname, finish:fin, material:mat, thread:th, paint:pnt, drivetype:driv});
		if (res.success) {
			let data = res.data;			
			let filtersdata = res.filtersdata;			
			setfilterscount(data);
			setfilterChilds(filtersdata)
					
		}else{
			setfilterscount({});
			setfilterChilds([])			
		}
	}
	// remove filters
	const RemoveParentCat = async (item) => {		
		setPcat({})
		setPcatId(0)
		setFilterCatID(0);
		setproducttypecount(pcatcount);	
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);
		setCcat1({})
		setCcat2({})
		setCcat3({})
		setCcat4({})
		setCcat5({})
		setCcat6({})
		setchild1(0)				
		setchild2(0)				
		setchild3(0)				
		setchild4(0)				
		setchild5(0)				
		setchild6(0)				
		let res = await getChildCatlistAction({p_cat:0});
		if (res.success) {
			let data = res.data;			
			setchildcats(data);				
		}else{
			setchildcats([]);
			setproducttypecount(0);	
		}	
	}
	const RemoveChild1 = async (item) => {
		setCcat1({})
		setCcat2({})
		setCcat3({})
		setCcat4({})
		setCcat5({})
		setCcat6({})
		setchild1(0)				
		setchild2(0)				
		setchild3(0)				
		setchild4(0)				
		setchild5(0)				
		setchild6(0)	
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);	
		setFilterCatID(0);			
		let res = await getChildCatlistAction({p_cat:pCatId});
		if (res.success) {
			let data = res.data;			
			setchildcats(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild2 = async (item) => {		
		setCcat2({})
		setCcat3({})
		setCcat4({})
		setCcat5({})
		setCcat6({})					
		setchild2(0)				
		setchild3(0)				
		setchild4(0)				
		setchild5(0)				
		setchild6(0)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);					
		let res = await getChildCatlistAction({p_cat:child1});
		if (res.success) {
			let data = res.data;			
			setchildcats2(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats2([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild3 = async (item) => {
		setCcat3({})
		setCcat4({})
		setCcat5({})
		setCcat6({})					
		setchild3(0)				
		setchild4(0)				
		setchild5(0)				
		setchild6(0)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);					
		let res = await getChildCatlistAction({p_cat:child2});
		if (res.success) {
			let data = res.data;			
			setchildcats3(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats3([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild4 = async (item) => {
		setCcat4({})
		setCcat5({})
		setCcat6({})					
		setchild4(0)				
		setchild5(0)				
		setchild6(0)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);					
		let res = await getChildCatlistAction({p_cat:child3});
		if (res.success) {
			let data = res.data;			
			setchildcats4(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats4([]);
			setproducttypecount(0);
		}	
	}
	const RemoveChild5 = async (item) => {		
		setCcat5({})
		setCcat6({})					
		setchild5(0)				
		setchild6(0)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);					
		let res = await getChildCatlistAction({p_cat:child4});
		if (res.success) {
			let data = res.data;			
			setchildcats5(data);	
			setproducttypecount(data.length);
		}else{
			setchildcats5([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveChild6 = async (item) => {		
		setCcat6({})					
		setchild6(0)
		setfinish(0);
		setmaterial(0);
		setthread(0);
		setpaint(0);
		setdrivetype(0);					
		let res = await getChildCatlistAction({p_cat:child5});
		if (res.success) {
			let data = res.data;			
			setchildcats6(data);
			setproducttypecount(data.length);	
		}else{
			setchildcats6([]);	
			setproducttypecount(0);
		}	
	}
	const RemoveFilter = async (item) => {		
		 if (item.type == config.finish) {
			setfinishData({});
			setfinish(0)
			getFilterAPIOnSelect(FilterCatID,productName,0,material,thread,paint,drivetype);
		  } else if (item.type == config.material) {
			setmaterialData({});
			setmaterial(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,0,thread,paint,drivetype);
		  } else if (item.type == config.thread) {
			setthreadData({});
			setthread(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,0,paint,drivetype);
		  } else if (item.type == config.paint) {
			setpaintData({});
			setpaint(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,0,drivetype);			
		  } else if (item.type == config.drive) {
			setdrivetypeData({});
			setdrivetype(0)
			getFilterAPIOnSelect(FilterCatID,productName,finish,material,thread,paint,0);
		  }	
	}
	// add to cart
	const AddToCart = async (product)  => {
		let res = await addToCartProductAction({product_id:product.id,hobson_part:product.hobson_part,cart_id:selectedQuoteId});
		if (res.success) {	
			updateordersummery(selectedQuoteId)		
			toast.success(res.msg);				
			//getUserPendingQuotesAPI();
			 const updatedQuotes = PendingQuotes.map(quote => {
			  if (quote.id === selectedQuoteId) {						
				return { ...quote, quote_products: res.data };
			  }
			  return quote;
			});
			setPendingQuotes(updatedQuotes);					
		}else{
			toast.error(res.msg);	
		}		
	}
	// remove from cart	 
	const CartRemoveProduct = async (cart_id,product_id)  => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Remove this product?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await removeCartProductAction({product_id:product_id,cart_id:cart_id});
				if (res.success) {	
					updateordersummery(cart_id)		
					//toast.success(res.msg);				
					 const updatedQuotes = PendingQuotes.map(quote => {
					  if (quote.id === cart_id) {						
						return { ...quote, quote_products: res.data };
					  }
					  return quote;
					});
					setPendingQuotes(updatedQuotes);					
				}else{
					toast.error(res.msg);	
				}
			}
		});
				
	}
	// cart	 
	const increaseProductQty = async (cart_id,product_id)  => {
		let res = await UpdateCartProductAction({product_id:product_id,cart_id:cart_id,action:'inc'});
			if (res.success) {	
				//toast.success(res.msg);
				 const updatedQuotes = PendingQuotes.map(quote => {
					  if (quote.id === cart_id) {						
						return { ...quote, quote_products: res.data };
					  }
					  return quote;
					});
				setPendingQuotes(updatedQuotes);	
				setTotals(res.cartTotal);					
			}else{
				toast.error(res.msg);	
			}				
	}
	const decreaseProductQty = async (cart_id,product_id)  => {
		let res = await UpdateCartProductAction({product_id:product_id,cart_id:cart_id,action:'dec'});
		if (res.success) {	
			//toast.success(res.msg);										
			 const updatedQuotes = PendingQuotes.map(quote => {
				  if (quote.id === cart_id) {						
					return { ...quote, quote_products: res.data };
				  }
				  return quote;
				});
			setPendingQuotes(updatedQuotes);	
			setTotals(res.cartTotal);				
		}else{
			toast.error(res.msg);	
		}				
	}
	const showOrderSummery = async () => {
		let res = await getCartOrderSummeryAction({cart_id:selectedQuoteId});
		if (res.success) {
			setTotals(res.data);
			setShowTotals(1);			
		}				
	}
	const hideOrderSummery = async () => {
		setShowTotals(0);
	}
	const updateordersummery = async (cart_id) => {
		let res = await getCartOrderSummeryAction({cart_id});
		if (res.success) {
			setTotals(res.data);					
		}	
	}
	const handleAddressChange = (e) => {
		setshowNewAddress(Number(e.target.value)); 
		if(Number(e.target.value)==3)
		{		
			setnewAddressForm((old) => {
				return { ...old, 'shipto': 2 };
			});
		}else{
			setnewAddressForm((old) => {
				return { ...old, 'shipto': 1 };
			});
		}
		
		
	  };
	const addclosebutton = async () => {
        setAddModalopen(0);        
    }
	const openclosebutton = async () => {
        setAddModalopen(1); 
        setshowAddressEditForm(0);       
    }
    const inputaddressHandler = async (e) => {
		const { name, value } = e.target
		setnewAddressForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const inputaddeditHandler = async (e) => {
		const { name, value } = e.target
		setAddressEditForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const SearchAddSuburb = async (str) => {
		try {
			setaddsuburb(str);
			setaddpostcode('');
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}catch (error) {
			console.error(error);
		}
	};	
	const SearchAddeditSuburb = async (str) => {
		try {
			setaddeditsuburb(str);
			setaddeditpostcode('');
			let res = await getSearchSuburbAction({str:str});
			setSuburbsearchResults(res.data);
		}catch (error) {
			console.error(error);
		}
	};	
	const handleNewsuburbSuburb = (row) => {	   
		setaddsuburb(row.suburb);
		setaddpostcode(row.postcode);
		setnewAddressForm((old) => {
			return { ...old, 'newsuburb': row.suburb };
		});
		setnewAddressForm((old) => {
			return { ...old, 'newpostcode': row.postcode };
		});
		setSuburbsearchResults({});
	};
	const editaddress = async (userAddress) => {
		let res = await GetAddressDetailsAction({address_id:userAddress.id});
		if (res.success) {	
			setAddressEditForm(res.data);			
			setaddeditsuburb(res.data.city);
			setaddeditpostcode(res.data.postcode);
			setshowAddressEditForm(1);  					
		}
	}
	const handleEditAddSuburb = (row) => {	   
		setaddeditsuburb(row.suburb);
		setaddeditpostcode(row.postcode);
		setAddressEditForm((old) => {
			return { ...old, 'city': row.suburb };
		});
		setAddressEditForm((old) => {
			return { ...old, 'postcode': row.postcode };
		});
		setSuburbsearchResults({});
	};
	const deleteUseraddress = async (userAddress) => {
		if(userAddress.set_default==1){
			toast.error('You can not delete you default address you can only modify it.');	
		}else{		
			Swal.fire({
				title: 'Are you sure? ',
				text: "You want to Delete this address?",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
			}).then(async (result) => {
				if (result.isConfirmed) {
					let res = await deleteUseraddressAction({address_id:userAddress.id});
					if (res.success) {	
						getUserProfileAPI();					
					}else{
						toast.error(res.msg);	
					}
				}
			});
		}
			
	}
	const updateShipToAddress = async (userAdd) => {
		let res = await updateShipToAddressAction({address_id:userAdd.id,user_id:loginData?.id,shipto:showNewAddress});
		if (res.success) {	
			getUserProfileAPI();
			setAddModalopen(0);  					
		}else{
			toast.error(res.msg);	
		}
			
	}  	
	function validateAddress() {
		let addressError = "";
		let suburbError = "";
		let stateError = "";
		let postcodeError = "";	
		let first_nameError = "";	
		let last_nameError = "";	
		let phoneError = "";	
		if(showNewAddress==3)
		{
			if (newAddressform.first_name === '') {
				first_nameError = "First name is required."
			}
			if (newAddressform.last_name === '') {
				last_nameError = "Last name is required."
			}
			if (newAddressform.phone === '') {
				phoneError = "Phone is required."
			}
			if (newAddressform.newaddress_1 === '') {
				addressError = "Address is required."
			}
			if (newAddressform.newsuburb === '') {
				suburbError = "Suburb is required."
			}
			if (newAddressform.newstate === '') {
				stateError = "State is required."
			}
			if (newAddressform.newpostcode === '') {
				postcodeError = "Postcode is required."
			}				
			if (addressError|| suburbError || stateError || postcodeError || first_nameError || last_nameError || phoneError) {
				setaddressvalidationError({
				  addressError, suburbError, stateError, postcodeError,first_nameError,last_nameError,phoneError	})
				return false
			} else {
				setaddressvalidationError({ addressError, suburbError, stateError, postcodeError,first_nameError,last_nameError,phoneError})
				return true
			}
			
		}else{
			if (newAddressform.newaddress_1 === '') {
				addressError = "Address is required."
			}
			if (newAddressform.newsuburb === '') {
				suburbError = "Suburb is required."
			}
			if (newAddressform.newstate === '') {
				stateError = "State is required."
			}
			if (newAddressform.newpostcode === '') {
				postcodeError = "Postcode is required."
			}				
			if (addressError|| suburbError|| stateError|| postcodeError) {
				setaddressvalidationError({
				  addressError, suburbError, stateError, postcodeError	})
				return false
			} else {
				setaddressvalidationError({ addressError, suburbError, stateError, postcodeError })
				return true
			}
		}
		
				
		
	}
	function validateOldAddress() {
		let addressError = "";
		let suburbError = "";
		let stateError = "";
		let postcodeError = "";	
		let first_nameError = "";	
		let last_nameError = "";	
		let phoneError = "";	
		if(showNewAddress==3)
		{
			if (AddressEditForm.first_name === '') {
				first_nameError = "First name is required."
			}
			if (AddressEditForm.last_name === '') {
				last_nameError = "Last name is required."
			}
			if (AddressEditForm.phone === '') {
				phoneError = "Phone is required."
			}
			if (AddressEditForm.address_1 === '') {
				addressError = "Address is required."
			}
			if (AddressEditForm.city === '') {
				suburbError = "Suburb is required."
			}
			if (AddressEditForm.state === '') {
				stateError = "State is required."
			}
			if (AddressEditForm.postcode === '') {
				postcodeError = "Postcode is required."
			}				
			if (addressError|| suburbError || stateError || postcodeError || first_nameError || last_nameError || phoneError) {
				setaddresseditvalidationError({
				  addressError, suburbError, stateError, postcodeError,first_nameError,last_nameError,phoneError	})
				return false
			} else {
				setaddresseditvalidationError({ addressError, suburbError, stateError, postcodeError,first_nameError,last_nameError,phoneError})
				return true
			}
			
		}else{		
			if (AddressEditForm.address_1 === '') {
				addressError = "Address is required."
			}
			if (AddressEditForm.city === '') {
				suburbError = "Suburb is required."
			}
			if (AddressEditForm.state === '') {
				stateError = "State is required."
			}
			if (AddressEditForm.postcode === '') {
				postcodeError = "Postcode is required."
			}				
			if (addressError|| suburbError|| stateError|| postcodeError) {
				setaddresseditvalidationError({
				  addressError, suburbError, stateError, postcodeError	})
				return false
			} else {
				setaddresseditvalidationError({ addressError, suburbError, stateError, postcodeError })
				return true
			}
		}
	}
	const AddAddress = async (e) => {
		e.preventDefault()
		const isValid = validateAddress();
		if (!isValid) {
		}else {			
			let res = await AddUserNewAddressAction(newAddressform);
			if (res.success) {
				toast.success(res.msg);
				getUserProfileAPI();	
				setaddsuburb('');
				setaddpostcode('');
				setnewAddressForm((old) => {
					return { ...old, 'newaddress_1': '' };
				});
				setnewAddressForm((old) => {
					return { ...old, 'newstate': '' };
				});
				setnewAddressForm((old) => {
					return { ...old, 'newsuburb': '' };
				});
				setnewAddressForm((old) => {
					return { ...old, 'newpostcode': '' };
				});
							
			} else {
				toast.error(res.msg);				
			}
		}
	}
	const updateAddress = async (e) => {
		e.preventDefault()
		const isValid = validateOldAddress();
		if (!isValid) {
		}else {			
			let res = await UpdateAddressAction(AddressEditForm);
			if (res.success) {
				toast.success(res.msg);
				getUserProfileAPI();	
				setaddeditsuburb('');
				setaddeditpostcode('');	
				setshowAddressEditForm(0);  					
			} else {
				toast.error(res.msg);				
			}
		}
	}
	const cancelEditButton = async () => {
        setshowAddressEditForm(0);       
    }
     // order section
    const inputPoHandler = async (e) => {		
		setPoOrder(e.target.value);
	}
    const OrderNow = async (cartId) => {
		setPoOrder('');
		setnewOrderCartID(cartId);
		ShowPoOrderPopup(1);
	}
    const PlaceOrder = async () => {
		
		if(newOrderCartID != null && newOrderCartID !=''){
			 let Order_address_id = defaultAddress.id;		 
			 if(showNewAddress === 2){
				 Order_address_id = defaultAddress2.id;
			 }else if(showNewAddress === 3){
				 Order_address_id = siteDeliveryAdd.id;
			 }	
			 ShowPoOrderPopup(0);  
			setDialogOpen(true);		 
			let res = await PlaceNewOrderAction({cart_id:newOrderCartID,address_id:Order_address_id,po_order:PoOrder});
			if (res.success) { 
				toast.success(res.msg);
				setTimeout(() => {
					window.location.reload();  
				}, 3000);
					
			}else{
				toast.error(res.msg);
				setDialogOpen(false);	
			}
			
		}else{
			toast.error("Invaild cart.");
		}
		
		 
		 
    }
    function afterOpenModal() { }
    const getUserOrdersListAPI = async () => {
		setOrdersListLoader(1);
		let res = await getUserOrdersListAction({user_id:loginData?.id});
		if (res.success) {
			let data = res.data
			setOrdersList(data);
			setOrdersListLoader(0);						
		}else{
			setOrdersList({pending:[],processing:[],backorders:[],completed:[]});	
			setOrdersListLoader(0);			
		}
	}
    const getOrderItems = async (order_id) => {
		let res = await getUserOrdersDetailsAction({user_id:loginData?.id,order_id:order_id});
		if (res.success) {
			let data = res.data
			setorderdetails(data);	
			setTimeout(() => {
				setodrmodalopen(1);	
			}, 500);
									
		}else{
			setorderdetails({});
			setodrmodalopen(0);	
		}
	}
	const Odrclosebutton = async () => {
        setodrmodalopen(0);        
    }
	const PoOrderPopupclosebutton = async () => {
        ShowPoOrderPopup(0);  
        setnewOrderCartID(null)      
    }
    const showCartProducts = (Plength) => {
       setVisible(Plength);
    }
	const CreateContactOnXERO = async () =>{
		let res = await CreateContactOnXEROAction();
		if (res.success) {
		}
		
	}
	
	return(
		<>
			<Header />
			 <Toaster />
			  <Modal
                isOpen={isDialogOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="text-center pl-3 pr-3">
                    < br />
						<h2 style={{ color: '#EB600A' }}>
                           Checking Stock
                        </h2>
                    <p style={{ color: '#091f3f' }}>
					   Please do not refresh the page or close the tab.<br />
                       Please wait while we process your order. Once completed, your order will appear in the Orders tab
                    </p>                    
                    <div>
                        <img src="images/loader.gif" height={50} width={50} />
                    </div>
                </div>
            </Modal>
			<div className="wrapper-content-banner">
				<div id="cartsection">					
					<div className="container">
					<div className="d-none" onClick={CreateContactOnXERO}>getInvoice</div>
						<div id="fixedcart" className="cart-inner-block">
							<Tabs selectedIndex={QuoteselectedIndex} onSelect={handleTabSelect}>
								<TabList className="account-box">
									<Tab className="nav-item cartsectionheader">Cart</Tab>									
									<Tab>ACCOUNT</Tab>
									<Tab>ORDERS</Tab> 
									{quoteLoder === 0 ? (
									  PendingQuotes.length > 0 ? (
										PendingQuotes.map((pq) => (
										  <Tab key={pq.id}>#{pq.quote_code}</Tab>
										))
									  ) : null
									) : null}

									
									<Tab onClick={CreateNewQuote}>+</Tab> 
								</TabList>	
																
								<TabPanel>	
									 <div className="company-name">
								 <h2 id="minimum_order_total"><span>CM Marketing</span> Cart </h2>
								 </div>
								</TabPanel>	
								<TabPanel>	
									 <div className="company-name">
										<h2 id="minimum_order_total"><span>CM Marketing</span> ACCOUNT </h2>
										</div>
										<div className="registration-heading mt-3">	
											<h2>Account details</h2>						
											<div className="form-section section-register">
												<div className="form-row"> 
													<div className="form-group col-md-6">
														<label for="first_name">First Name<span className="req-star">*</span></label>
														<input type="text" className={`form-control ${validationError.first_nameError ? 'is-invalid' : ''}`}  id="first_name" name="first_name"  placeholder="Enter first name" onChange={inputHandler}  value={UserProfile.first_name}/>
													</div>
													<div className="form-group col-md-6">
														<label for="last_name">Last Name<span className="req-star">*</span></label>
														<input type="text" className={`form-control ${validationError.last_nameError ? 'is-invalid' : ''}`} id="last_name" name="last_name" placeholder="Enter last namee"  onChange={inputHandler}  value={UserProfile.last_name}/>
													</div>												
												<div className="form-group col-md-6">
													<label for="email">Email Address*</label>
													<input type="email" className={`form-control ${validationError.emailError ? 'is-invalid' : ''}`} id="email" name="email" readonly disabled placeholder="Enter email address" onChange={inputHandler} value={UserProfile.email} />
												</div>												
												<div className="form-group col-md-6">
													<label for="phone">Mobile *</label>
													<InputMask 
													type='text' 
													className={`form-control ${validationError.phoneError ? 'is-invalid' : ''}`} name='phone' 
													id='phone' 
													value={UserProfile.phone} 
													onChange={inputHandler} 
													onKeyDown={handleKeyDown}
													mask="____ ___ ___" 
													replacement={{ _: /\d/ }} 
													placeholder="____ ___ ___" />
												</div>										
												<div className="form-group col-md-6">
													<label for="company">Company name*</label>
													<input type="text" className={`form-control ${validationError.companyError ? 'is-invalid' : ''}`} id="company" name="company" placeholder="Enter company name" readonly disabled value={UserProfile.company} />
												</div>
												<div className="form-group col-md-6">
													<label for="address">Address *</label>
													<input type="text" className="form-control" id="address_1" name="address_1" placeholder="Enter address" onChange={inputHandler} value={UserProfile.address_1}/>
												</div>
												<div className="form-group col-md-6">
												<label for="exampleInputEmail1">Suburb *</label>
													<input type="text" className="form-control" id="suburb" name="suburb" placeholder="Enter suburb" autocomplete="off" onChange={(e) => SearchSuburb(e.target.value)}  value={suburb} />
													{SuburbsearchResults.length > 0 ? (
														<ul className="suburbList">
															{SuburbsearchResults.map((row) => (
																<li key={row.id} onClick={() => handleSelectSuburb(row)}>
																	{row.rowlocation} 
																</li>
															))}
														</ul>
													) : null}
												</div>
												<div className="form-group col-md-6">
													<label for="exampleInputEmail1">State *</label>
													<select className="form-control" id="state" name="state" onChange={inputHandler} value={UserProfile.state}>
													  <option>Select</option>
													  <option value="ACT">Australian Capital Territory</option>
													  <option value="NSW">New South Wales</option>
													  <option value="NT">Northern Territory</option>
													  <option value="QLD">Queensland</option>
													  <option value="SA">South Australia</option>
													  <option value="TSA">Tasmania</option>
													  <option value="VIC">Victoria</option>
													  <option value="WA">Western Australia</option>
													</select>	
												</div>
												<div className="form-group col-md-6">
													<label for="postcode">Postcode *</label>
													<input type="postcode" value={postcode} className="form-control" id="postcode" name="postcode" placeholder="Enter postcode" readonly disabled />
												</div>
											</div>
										</div>
										<div className="login-button text-right">
											<button className="login-btn" onClick={UpdateProfile} disabled={spinLoader==1 ? true : ''} >{spinLoader==0 ? 'UPDATE DETAILS' : 'UPDATING...' }</button>
										</div>
									</div>
									<div className="registration-heading mt-3">	
										<h2>Change Password</h2>						
										<div className="form-section section-register">
											<div className="form-row"> 
											<div className="form-group col-md-4">
												<label for="curpassword">Current Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.curpasswordError ? 'is-invalid' : ''}`} id="curpassword" placeholder="Enter Current Password" name="curpassword" onChange={inputPwHandler} value={form.curpassword} />
											</div>
											<div className="form-group col-md-4">
												<label for="password">New Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.passwordError ? 'is-invalid' : ''}`} id="password" placeholder="Enter New Password" name="password" onChange={inputPwHandler} value={form.password} />
											</div>
											<div className="form-group col-md-4">
												<label for="cpassword">Confirm Password *</label>
												<input type="password" className={`form-control ${pwvalidationError.cpasswordError ? 'is-invalid' : ''}`} id="cpassword" placeholder="Enter Confirm Password" name="cpassword" onChange={inputPwHandler} value={form.cpassword} />
											</div>											
										</div>
										</div>
										<div className="login-button text-right">
											<button className="login-btn" onClick={UpdateUserPassword} disabled={spinpwLoader==1 ? true : ''}>{spinpwLoader==0 ? 'UPDATE PASSWORD' : 'UPDATING...' }</button>
										</div>
									</div>
								</TabPanel>	
								<TabPanel>	
									 <div className="company-name">
										<h2 id="minimum_order_total"><span>CM Marketing</span> ORDERS </h2>
								  </div>
								 {OrdersList.pending.length > 0 ? (
								  <>
									<div className="table-responsive product-table">
									  <table className="table table-condensed prizecollection">
										<thead>
										  <tr>
											<th style={{ width: "20%" }}>Pending</th>
											<th style={{ width: "25%" }}>PO Number</th>
											<th style={{ width: "25%" }}>Date</th>
											<th style={{ width: "10%" }}>View Order</th>											
											<th className="totablock" style={{ width: "20%" }}>TOTAL</th>
										  </tr>
										</thead>
										<tbody>
										  {OrdersList.pending.map((order) => (
											<tr key={order.order_id}>
											  <td><p>#{order.order_id}</p></td>
											  <td className="toponumber"><p>{order.po_number}</p></td>
											  <td className="tbdate"><p>{new Date(order.date_added).toLocaleDateString('en-GB')} {new Date(order.date_added).toLocaleTimeString()}</p></td>
											  <td className="tbvieworder">
												<p className="cr-ptr" onClick={() => getOrderItems(order.order_id)}>View Order</p>
											  </td>											  
											  <td className="totaprize"><p>${order.total}</p></td>
											</tr>
										  ))}
										</tbody>
									  </table>
									</div>
								  </>
								) : null}
								 {OrdersList.processing.length > 0 ? (
								  <>
									<div className="table-responsive product-table">
									  <table className="table table-condensed prizecollection">
										<thead>
										  <tr>
											<th style={{ width: "20%" }}>Processing</th>
											<th style={{ width: "25%" }}>PO Number</th>
											<th style={{ width: "25%" }}>Date</th>
											<th style={{ width: "10%" }}>View Order</th>											
											<th style={{ width: "10%" }}>Invoice</th>											
											<th className="totablock" style={{ width: "10%" }}>TOTAL</th>
										  </tr>
										</thead>
										<tbody>
										  {OrdersList.processing.map((order) => (
											<tr key={order.order_id}>
											  <td><p>#{order.order_id}</p></td>
											  <td className="toponumber"><p>{order.po_number}</p></td>
											  <td className="tbdate"><p>{new Date(order.date_added).toLocaleDateString('en-GB')} {new Date(order.date_added).toLocaleTimeString()}</p></td>
											  <td></td>
											  <td className="tbvieworder">
												<p className="cr-ptr" onClick={() => getOrderItems(order.order_id)}>View Order</p>
											  </td>											  
											  <td className="totaprize"><p>${order.total}</p></td>
											</tr>
										  ))}
										</tbody>
									  </table>
									</div>
								  </>
								) : null}
								 {OrdersList.backorders.length > 0 ? (
								  <>
									<div className="table-responsive product-table">
									  <table className="table table-condensed prizecollection">
										<thead>
										  <tr>
											<th style={{ width: "20%" }}>Backorders</th>
											<th style={{ width: "25%" }}>PO Number</th>
											<th style={{ width: "25%" }}>Date</th>
											<th style={{ width: "10%" }}>View Order</th>											
											<th style={{ width: "10%" }}>Invoice</th>											
											<th className="totablock" style={{ width: "10%" }}>TOTAL</th>
										  </tr>
										</thead>
										<tbody>
										  {OrdersList.backorders.map((order) => (
											<tr key={order.order_id}>
											  <td><p>#{order.order_id}</p></td>
											  <td className="toponumber"><p>{order.po_number}</p></td>
											  <td className="tbdate"><p>{new Date(order.date_added).toLocaleDateString('en-GB')} {new Date(order.date_added).toLocaleTimeString()}</p></td>
											  <td></td>
											  <td className="tbvieworder">
												<p className="cr-ptr" onClick={() => getOrderItems(order.order_id)}>View Order</p>
											  </td>											  
											  <td className="totaprize"><p>${order.total}</p></td>
											</tr>
										  ))}
										</tbody>
									  </table>
									</div>
								  </>
								) : null}
								 {OrdersList.completed.length > 0 ? (
								  <>
									<div className="table-responsive product-table">
									  <table className="table table-condensed prizecollection">
										<thead>
										  <tr>
											<th style={{ width: "20%" }}>Completed</th>
											<th style={{ width: "25%" }}>PO Number</th>
											<th style={{ width: "25%" }}>Date</th>
											<th style={{ width: "10%" }}>View Order</th>											
											<th style={{ width: "10%" }}>Invoice</th>											
											<th className="totablock" style={{ width: "10%" }}>TOTAL</th>
										  </tr>
										</thead>
										<tbody>
										  {OrdersList.completed.map((order) => (
											<tr key={order.order_id}>
											  <td><p>#{order.order_id}</p></td>
											  <td className="toponumber"><p>{order.po_number}</p></td>
											  <td className="tbdate"><p>{new Date(order.date_added).toLocaleDateString('en-GB')} {new Date(order.date_added).toLocaleTimeString()}</p></td>
											  <td></td>
											  <td className="tbvieworder">
												<p className="cr-ptr" onClick={() => getOrderItems(order.order_id)}>View Order</p>
											  </td>											  
											  <td className="totaprize"><p>${order.total}</p></td>
											</tr>
										  ))}
										</tbody>
									  </table>
									</div>
								  </>
								) : null}

								  
								</TabPanel>	
						{quoteLoder === 0 ? (
							  PendingQuotes.length > 0 ? (
								PendingQuotes.map((pq) => (
								  <TabPanel key={pq.id}>
									<div className="company-name">
									  <h2 id="minimum_order_total">
										<span>CM Marketing</span> #{pq.quote_code}
									  </h2>
									</div>
									{pq.quote_products && pq.quote_products.length > 0 ? (
									  <div className="table-responsive product-table">
										<table className="table table-condensed table-striped">
										  <thead>
											<tr>
											  <th></th>
											  <th>PART</th>
											  <th>DESCRIPTION</th>
											  <th>PRODUCT</th>
											  <th>PRICE</th>
											  <th>QUANTITY</th>
											  <th>SUB TOTAL</th>
											  <th></th>
											</tr>
										  </thead>
										  <tbody>
											{pq.quote_products.slice(0, visible).map((quote_product) => (
											  <React.Fragment key={quote_product.product_id}>
												<tr
												  id={`cart_item_${quote_product.product_id}`}
												  className={quote_product.product_quantity > 0 ? 'main-sec-table':'main-sec-table deletedrecord'}									  
												  
												>
												  <td onClick={() => toggleProductRow(quote_product.product_id)} style={{ cursor: 'pointer' }}>{expandedProductRows[quote_product.product_id] ? (<img className="arrowicon arrowicon-down" src="images/icon-right.png" />) : (<img className="arrowicon" src="images/icon-right.png" />)}</td>
												  <td onClick={() => toggleProductRow(quote_product.product_id)} style={{ cursor: 'pointer' }}>{quote_product.product_sku}</td>
												  <td onClick={() => toggleProductRow(quote_product.product_id)} style={{ cursor: 'pointer' }}>{quote_product.product_description}</td>
												  <td onClick={() => toggleProductRow(quote_product.product_id)} style={{ cursor: 'pointer' }}>{quote_product.product_extra_description}</td>
												  <td>${quote_product.product_per_price}</td>
												  <td>
													  <div className="quntity-box">
														  <div className="value-button" id="decrease" onClick={() => decreaseProductQty(pq.id,quote_product.product_id)}>-</div>
														 <input type="text" className="form-control proquantity" value={quote_product.product_quantity} />
														  <div className="value-button" id="increase" onClick={() => increaseProductQty(pq.id,quote_product.product_id)} >+</div>
														</div>
													  
													  </td>
												  <td className="item_price">
													${(quote_product.product_quantity * quote_product.product_per_price).toFixed(2)}
												  </td>
												  <td><div onClick={() => CartRemoveProduct(pq.id,quote_product.product_id)} className="cancel-box">X</div></td>
												</tr>
												{expandedProductRows[quote_product.product_id] && (
												  <tr className="product-details-row">
													<td colSpan="8">
													  <div className="product-details sec-botm">
														<div className="row">
															  <div className="col-3">
																<div className="doucoment text-center"> <img className="mx-ht-200" src={quote_product.image_path} /> </div>
															  </div>
															  <div className="col-lg-9">
											<Tabs>
												<TabList>
													<Tab>Quantity</Tab>									
													<Tab>DETAILS</Tab>
													<Tab>DOCUMENTS</Tab>	      
													<Tab>PACKAGING</Tab>	      
												</TabList>									
												<TabPanel>	
													 <div className="row">
														 <div className="col-md-6">
															 <div className="maintable">
																<div className="tablerow">
																<div className="tablehead">
																	<p>Quantity</p>
																</div>
																<div className="tablehead">
																	<p title="Standard">Price <small>/100</small></p>
																</div>
																</div>
																
																{quote_product.product_prices.length > 0 ? (
															  <>   
																{quote_product.product_prices
																  .filter(p_price => p_price.price_type === 'std')
																  .map(p_price => (
																	<div className="tablerow" key={`std-${p_price.id}`}>
																	  <div className="tabledata ">
																		<p className="darkorange">{p_price.quantity_break}</p>
																	  </div>
																	  <div className="tabledata ">
																		<p className="darkorange">{p_price.price}</p>
																	  </div>
																	</div>
																  ))}  
																{quote_product.product_prices.some(p_price => p_price.price_type === 'spc') ? (
																  <>
																	<div className="tablerow">
																		<div className="tablehead">
																			<p>Special Prices</p>
																		</div>
																		<div className="tablehead">
																			<p title="Standard">&nbsp;</p>
																		</div>
																	</div>
																	{quote_product.product_prices
																	  .filter(p_price => p_price.price_type === 'spc')
																	  .map(p_price => (
																		<div className="tablerow" key={`spc-${p_price.id}`}>
																		  <div className="tabledata ">
																			<p className="darkorange">{p_price.quantity_break}</p>
																		  </div>
																		  <div className="tabledata ">
																			<p className="darkorange">{p_price.price}</p>
																		  </div>
																		</div>
																	  ))}
																  </>
																) :
																  null
																}
															  </>
															) : null}

															</div>	
														 </div>	
														 <div className="col-md-6">
															 
														 </div> 
													 </div>
												</TabPanel>	
												<TabPanel>	
												<div className="row">
													<div className="col-md-12">
														<div className="product-code-contant">
															<div className="product-code"> <span>Product Code</span>
																<p>{quote_product.hobson_part}</p>
																<div className="ription"> <span>Description</span>
																	<p>{quote_product.product_description}</p>
																</div>
															</div>
														</div>
												<div className="row">
												<div className="col-md-4 col-sm-6">
												<div className="brass-box-contant">
												<div className="brass"> <span>Finish</span>
												<p>{quote_product.finish}</p>
												</div>
												</div>
												</div>
												<div className="col-md-4 col-sm-6">
												<div className="brass-box-contant">
												<div className="brass"> <span>Size</span>
												<p>{quote_product.size}</p>
												</div>
												</div>
												</div>
												<div className="col-md-4 col-sm-6">
												<div className="brass-box-contant">
												<div className="brass"> <span>Product</span>
												<p>{quote_product.product_extra_description}</p>
												</div>
												</div>
												</div>
												<div className="col-md-4 col-sm-6">
												<div className="brass-box-contant">
												<div className="brass brass-product"> <span>Specification</span>
												<p>{quote_product.spec}</p>
												</div>
												</div>
												</div>
												</div>
												</div>
												</div>
												</TabPanel>
												<TabPanel>	
													<div className="pt-3">
													<div className="col-md-12">
													<div className="product-code-contant">
													<div className="product-code">
													 {quote_product.product_documents.length > 0 ? (
														  <>  
														  <ul className="downloadlist"> 
															{quote_product.product_documents.map(p_document => (
																<li><a rel="noopener noreferrer" target="_blank" href={p_document.url}>{p_document.description}</a></li>
															  ))}
															  </ul>
															  </>
															) :
															  null
															}
														</div>
													</div>
													</div>
													</div>
												</TabPanel>
												<TabPanel>	
													<div className="pt-3">
													<div className="col-md-12">													
													 {quote_product.product_packings.length > 0 ? (
														  <> 
															{quote_product.product_packings.map(p_packing => (
																 <> <h5><u>{p_packing.description}</u></h5>
																	<ul className="packagelist">
																	<li><span>Dimensions:</span> {p_packing.dimensions}</li>
																	<li><span>Quantity:</span> {p_packing.quantity}</li>
																	<li><span>Weight:</span> {p_packing.weight}</li>
																	<li><span>Barcode:</span> {p_packing.barcode}</li>
																	</ul>
																	</>
															  ))}															
															  </>
															) :
															  null
															}
													</div>
													</div>
												</TabPanel>
											</Tabs>  
															   
															  </div>
														</div>
													  </div>
													</td>
												  </tr>
												)}
											  </React.Fragment>
											))}
											{pq.quote_products.length > 10 ? (
												<>
											<tr>
											<td colspan="8" className="text-right ">
												<div onClick={() => visible === pq.quote_products.length 
												? showCartProducts(config.visible) 
												: showCartProducts(pq.quote_products.length)
											  } 
											  className="pdd-r27"> 
											  {visible === pq.quote_products.length ? (
												<>
												  <span className="showhcollepse">Collapse&nbsp;&nbsp;</span>
												  <img title="Show Cart" width={15} className="icon-down" src="images/icon-right.png" />
												</>
											  ) : (
												<>
												  <span className="showhcollepse">Open&nbsp;&nbsp;</span>
												  <img title="Show Cart" width={15} className="icon" src="images/icon-right.png" />
												</>
											  )}
											</div>
										</td></tr>
										</>
										):null}
										  </tbody>
										</table>
										
									  </div>
																	  							
									) : (
									  <div className="table-responsive product-table">
										<table className="table table-condensed table-striped">
										  <thead>
											<tr>
											  <th>PART</th>
											  <th>DESCRIPTION</th>
											  <th>PRODUCT</th>
											  <th>PRICE</th>
											  <th>QUANTITY</th>
											  <th>SUB TOTAL</th>
											  <th></th>
											</tr>
										  </thead>
										  <tbody>
											<tr className="strip-bg text-center">
											  <td colSpan="7">You do not have any products in this order.</td>
											</tr>
										  </tbody>
										</table>
									  </div>
									)}
								  </TabPanel>
								))
							  ) : null
							) : null}
							</Tabs>
							{QuoteselectedIndex !=1 && QuoteselectedIndex !=2 ? (	
							  <>
								<div className="delivery-method">
								  <div className="row">
									<div className="col-md-6">
									  <div className="row">
										<div className="col-md-4 d-none">
										  <div className="deliver-block">
											<h4>DELIVERY METHOD</h4>			
											<div className="form-check">
											  <label className="form-check-label" htmlFor="shipmethod-CMM0016-2">
												<input type="radio" className="form-check-input shipmethod" defaultChecked />
												Delivered
											  </label>
											</div>			 
										  </div>
										</div>
										<div className="col-md-12">
										  <div className="deliver-section">
											<h4>DELIVERY ADDRESS</h4>
											<div className="deliver-detail">
											  <div className="pickup">
												<form>
												 <div className="form-check">
													  <label className="form-check-label">
														<input
														  type="radio"
														  className="form-check-input"
														  name="opt"
														  value="1"
														  checked={showNewAddress === 1}
														  onChange={handleAddressChange}
														/>
														Ship to
													  </label>
													</div>
													<div className="form-check">
													  <label className="form-check-label">
														<input
														  type="radio"
														  className="form-check-input"
														  name="opt"
														  value="2"
														  checked={showNewAddress === 2}
														  onChange={handleAddressChange}
														/>
														New Address
													  </label>
													</div>
													<div className="form-check">
													  <label className="form-check-label">
														<input
														  type="radio"
														  className="form-check-input"
														  name="opt"
														  value="3"
														  checked={showNewAddress === 3}
														  onChange={handleAddressChange}
														/>
														Site Delivery
													  </label>
													</div>

												</form>
											  </div>
											  <div className="delicer-address">
											  {showNewAddress==1 ? (
												  <>
													<p className="mb-0"> 
													{defaultAddress?.address_1}<br />
													{defaultAddress?.city}&nbsp;{defaultAddress?.state}&nbsp;{defaultAddress?.postcode}
													</p>
													</>
													): showNewAddress==2 ? 
													(<>
													{defaultAddress2?.address_1}<br />
													{defaultAddress2?.city}&nbsp;{defaultAddress2?.state}&nbsp;{defaultAddress2?.postcode}
													<p><a href="javascript:;" onClick={openclosebutton}>{defaultAddress2.length > 0 ? 'Change Address' :'Add new address'}</a></p>
													</>):
													(<>
													{siteDeliveryAdd?.address_1}<br />
													{siteDeliveryAdd?.city}&nbsp;{siteDeliveryAdd?.state}&nbsp;{siteDeliveryAdd?.postcode}
													<p><a href="javascript:;" onClick={openclosebutton}>{siteDeliveryAdd.length > 0 ? 'Change Address' :'Add new address'}</a></p>
													</>)
													
													}
													
											  </div>			 
											</div>
										  </div>
										</div>
									  </div>
									</div>
									<div className="col-md-3">
									  <div className="order-summery">
										<h4>ORDER SUMMARY</h4>
										<ul>
										  <li>
											<span className="subtotal">Subtotal</span> 
											<span className="subtotal-prize">${totals ? totals.subtotal : 0.00}</span>
										  </li>
										  <li>
											<span className="subtotal">Delivery</span> 
											<span className="subtotal-prize">$0.00</span>
										  </li>
										  <li>
											<span className="subtotal">GST({totals ? totals.gst : 0.00}%)</span> 
											<span className="subtotal-prize">${totals ? totals.gstontotal : 0.00}</span>
										  </li>
										  <li>
											<span className="subtotal"><b>Total</b></span> 
											<span className="subtotal-prize">${totals ? totals.total : 0.00}</span>
										  </li>
										</ul>
									  </div>
									</div>
									<div className="col-md-3">
									  <div className="order-confirmsection"> 		
										<a className="btn ordernowsquare" onClick={ () => OrderNow(totals.cart_id)}>ORDER NOW</a> 
										<a className="btn ordercancel mt-2">CANCEL ORDER</a> 
									  </div>								
									</div>
								  </div> 
								</div>
							  </>
							) : null }

							
						 </div>
						</div>
					 </div>
{ QuoteselectedIndex !=1 && QuoteselectedIndex !=2 ?(					 
<div className="container">
<div className="background-color productnew-sections">
  <div className="row align-items-center">
	<div className="col-lg-2">
	  <h2 className="products_title">PRODUCTS</h2>
	</div>
	<div className="col-lg-6">
	  <div className="search-product"> <a href="javascript:;" onClick={clearallsearch} className="newsearch">New search</a>		
		<div className="searchform">
		  <input type="text" name="search" id="search" value={productName} placeholder="Search Products" fdprocessedid="twezrt"  onChange={inputSearchHandler}/>
		  {productName !='' ? (<a href="javascript:;" className="clearsearch" onClick={clearsearchtext} >X</a>) :''}
		  <p id="searchalert" className="searchalert d-none">You must enter a min of 4 characters.</p>
		</div>
	  </div>
	</div>
	<div className="col-lg-4 pagination-container">
	  <div className="pages-link">		
	  </div>
	</div>
  </div>
</div>  
<div className="main-section-tabs">
  <div className="col-md-12">
	<div className="tabs-section">
	  <ul className="nav nav-tabs" role="tablist">
		<li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#tabs-1" role="tab">
		  <h6>PRODUCT TYPE {producttypecount > 0 ? `(${producttypecount})` : ''}</h6>
		  </a> </li>
		  {pCatId != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {pCatData?.description}<span className="catclose" onClick={() => RemoveParentCat(pCatData)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child1 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData1?.description}<span className="catclose" onClick={() => RemoveChild1(CCatData1)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child2 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData2?.description}<span className="catclose" onClick={() => RemoveChild2(CCatData2)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child3 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData3?.description}<span className="catclose" onClick={() => RemoveChild3(CCatData3)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child4 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData4?.description}<span className="catclose" onClick={() => RemoveChild4(CCatData4)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child5 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData5?.description}<span className="catclose" onClick={() => RemoveChild5(CCatData5)}>&nbsp;X</span></a></li>
		  ):''}
		  
		  {child6 != 0 ? (
		   <li className="cattabs_open"><a href="javascript:;"> {CCatData6?.description}<span className="catclose" onClick={() => RemoveChild6(CCatData6)}>&nbsp;X</span></a></li>
		  ):''}
		  		
	  </ul>
	  <div className="tab-content">
		<div className="tab-pane active" id="tabs-1" role="tabpanel">
		  <div className="main-tabs-section">
			  {pcategories.length > 0 && pCatId===0 ? (
				pcategories.map((pcat) => (
					<div key={pcat.id} className="bolts-post" style={{ opacity: ProductsAllCats.length > 0 ? ProductsAllCats.includes(pcat.id) ? 1 : 0.5 :1 }}>
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(pcat.id) ? () => SetParentCat(pcat) : null : () => SetParentCat(pcat)}> 
							<img src={pcat.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(pcat.id) ? () => SetParentCat(pcat) : null : () => SetParentCat(pcat)} >
						<p>{pcat.description}</p>
					  </div>
					</div>
				))
			) : null} 
			
			{childcats.length > 0 && child1===0 && pCatId != 0 ? (
				childcats.map((ccat) => (
					<div  key={ccat.id} className="bolts-post" style={{ opacity: ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat.id) ? 1 : 0.5 : 1 }}>
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat.id) ? () => Setchild1(ccat): null :() => Setchild1(ccat)}>
							<img src={ccat.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat.id) ? () => Setchild1(ccat): null :() => Setchild1(ccat)}>
						<p>{ccat.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats2.length > 0 && child2===0 && child1!= 0 && pCatId != 0 ? (
				childcats2.map((ccat2) => (
					<div key={ccat2.id}  className="bolts-post" style={{ opacity: ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat2.id) ? 1 : 0.5 : 1 }}>
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat2.id) ? () => Setchild2(ccat2): null : () => Setchild2(ccat2) }> 
							<img src={ccat2.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat2.id) ? () => Setchild2(ccat2): null : () => Setchild2(ccat2) }>
						<p>{ccat2.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats3.length > 0 && child3===0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats3.map((ccat3) => (
					<div key={ccat3.id} className="bolts-post" style={{ opacity: ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat3.id) ? 1 : 0.5 : 1 }}>
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat3.id) ? () => Setchild3(ccat3): null : () => Setchild3(ccat3) }> 
							<img src={ccat3.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat3.id) ? () => Setchild3(ccat3): null : () => Setchild3(ccat3) }>
						<p>{ccat3.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats4.length > 0 && child4===0 && child3 != 0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats4.map((ccat4) => (
					<div key={ccat4.id} className="bolts-post" style={{ opacity: ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat4.id) ? 1 : 0.5 : 1 }}>
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat4.id) ? () => Setchild4(ccat4): null : () => Setchild4(ccat4) }> 
							<img src={ccat4.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat4.id) ? () => Setchild4(ccat4): null : () => Setchild4(ccat4) }>
						<p>{ccat4.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats5.length > 0 && child5===0 && child4 != 0  && child3 != 0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats5.map((ccat5) => (
					<div key={ccat5.id} className="bolts-post" style={{ opacity: ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat5.id) ? 1 : 0.5 : 1 }}>
					  <div className="images-bogls-contant">
						<div className="shapes" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat5.id) ? () => Setchild5(ccat5): null : () => Setchild5(ccat5)}> 
							<img src={ccat5.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant" onClick={ ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat5.id) ? () => Setchild5(ccat5): null : () => Setchild5(ccat5)}>
						<p>{ccat5.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			{childcats6.length > 0 && child6===0 && child5 != 0 && child4 != 0 && child3 != 0 && child2 != 0 && child1!= 0 && pCatId != 0 ? (
				childcats6.map((ccat6) => (
					<div key={ccat6.id} className="bolts-post" style={{ opacity: ProductsAllCats.length > 0 ? ProductsAllCats.includes(ccat6.id) ? 1 : 0.5 : 1 }}>
					  <div className="images-bogls-contant">
						<div className="shapes"> 
							<img src={ccat6.image_url} /> </div>
					  </div>
					  <div className="fasteners-contant">
						<p>{ccat6.description}</p>
					  </div>
					</div>
				))
			) : null}
			
			
			
			</div>
		</div>		
	  </div>
	</div>	
	{FilterCatID !=0 ? (
	<Tabs className="tabs-btn tabbable">
	<TabList className="nav nav-tabs" id="filtersTab">
		
		{masterfilters.length > 0 ? (
		  masterfilters.map((fltr,index) => (
				<Tab disabled={fltr.id==config.finish && finish !=0 ? true : fltr.id==config.material && material !=0 ? true : fltr.id==config.thread && thread  !=0  ? true : fltr.id==config.paint && paint !=0 ? true : fltr.id==config.drive && drivetype !=0 ?  true :'' }>{fltr.description} ({fltr.id==config.finish ? filterscount.finish : fltr.id==config.material ? filterscount.material : fltr.id==config.thread   ? filterscount.thread : fltr.id==config.paint ? filterscount.paint : fltr.id==config.drive ? filterscount.drive :'0' })</Tab>
			))): null}
		  
		   {finish != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {finishData?.description}<span className="catclose" onClick={() => RemoveFilter(finishData)}>&nbsp;X</span></a></li>
		  ):''}
		  
		   {material != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {materialData?.description}<span className="catclose" onClick={() => RemoveFilter(materialData)}>&nbsp;X</span></a></li>
		  ):''}
		   {thread != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {threadData?.description}<span className="catclose" onClick={() => RemoveFilter(threadData)}>&nbsp;X</span></a></li>
		  ):''}
		   {paint != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {paintData?.description}<span className="catclose" onClick={() => RemoveFilter(paintData)}>&nbsp;X</span></a></li>
		  ):''}
		   {drivetype != 0 ? (
		   <li className="filtertab_open"><a href="javascript:;"> {drivetypeData?.description}<span className="catclose" onClick={() => RemoveFilter(drivetypeData)}>&nbsp;X</span></a></li>
		  ):''}
	</TabList>	
		{filterChilds.length > 0 ? (
			filterChilds.map((fl) => (			 
				<TabPanel  key={fl.filter_id} className="filter-group">	
					<div className="main-tabs-section">
				  {fl.children.map((child) => (
					   <div className="bolts-post" key={child.child_id}>
						  <div className="images-bogls-contant">
							<div className="shapes" onClick={() => SetFilterfltr(child,fl.filter_id)}> <img src={child.image_url} alt={child.title} /></div>
						  </div>
						  <div className="fasteners-contant" onClick={() => SetFilterfltr(child,fl.filter_id)}>
							<p>{child.description}</p>
						  </div>
						</div>					
				  ))}
				  </div>		
				</TabPanel>	
			))
		  ) :null}
	</Tabs>	 
	):''} 
	
	  <div className="table-responsive product-table">
		  <table className="table">
			<thead>
			  <tr>
				<th scope="col">PART</th>
				<th scope="col">DESCRIPTION</th>
				<th scope="col">PRODUCT</th>
				<th scope="col">FINISH</th>
				<th scope="col">SIZE</th>
				<th scope="col">LENGTH</th>
			  </tr>
			</thead>
			<tbody>
			{Allproducts.length > 0 ? (
				Allproducts.map((product,index) => (
					<tr className={index%2==0 ? 'strip-bg cr-ptr':'cr-ptr'}  onClick={() => AddToCart(product)} title="Add to cart">
					<td>{product.hobson_part}</td>
					<td>{product.description}</td>
					<td>{product.extra_description}</td>
					<td>{product.finish}</td>
					<td>{product.size}</td>
					<td>{product.length}</td>
				  </tr>
				))
			) : null}			 
			</tbody>
		  </table>		  
	</div>
	{totalPages > 0 ?										  
			<Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
			: ''
		}	
  </div>
</div>
</div>)
:''}
</div>
<div id="dorshipaddress" className={addmodalopen == '0' ? "modal fade cart-modal mc-popup dorshipaddress" : "dorshipaddress mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgb(255 255 255 / 61%)', display: addmodalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                     <div className="modal-header">
						<h5 className="modal-title" id="exampleModalLongTitle">NEW ADDRESS</h5>
						<button type="button" onClick={addclosebutton} className="close btnClose" data-dismiss="modal" aria-label="Close">
						  <span aria-hidden="true">&times;</span>
						</button>
					  </div>						                                     
                         <div className="modal-body">
						   <Tabs>
								<TabList>
									<Tab>Addresses</Tab>									
									<Tab>Add</Tab>										      
								</TabList>	
															
								<TabPanel>								
								 {showAddressEditForm===0 ? (
									 UserAddress.length > 0 ? (
									  <> 
										<div className="row">
										  { showNewAddress==3 ? (
											  UserAddress.filter(usr_adr => usr_adr.shipto === 2).map(usr_adr => (
											<> 
											  <div className="col-md-6" key={usr_adr.id}>
												<div id="address_area_42" className={usr_adr.set_default === 1 ? 'address-item address-block default_address' : 'address-item address-block'}>
												  <div title="Select this Address" className="address" onClick={() => updateShipToAddress(usr_adr)}>
													{showNewAddress==3 ? 
														(
														<>
														<p>{usr_adr.first_name} &nbsp;{usr_adr.last_name}</p>
														<p>{usr_adr.phone}</p>
														</>
														):null
													}
													<p>{usr_adr.address_1}</p>													
													<p>{usr_adr.city} {usr_adr.state}, {usr_adr.postcode}</p> 
												  </div>
												  <div className="linkswrapper">
													<a title="Edit Address" onClick={() => editaddress(usr_adr)} href="javascript:void(0);"><i className="fa fa-edit"></i></a>
													<a title="Delete Address" onClick={() => deleteUseraddress(usr_adr)} href="javascript:void(0);"><i className="fa fa-trash"></i></a>
												  </div>
												</div>	
											  </div>
											</>
										  ))
										  ) :
										  (
											  UserAddress.filter(usr_adr => usr_adr.shipto === 1).map(usr_adr => (
											<> 
											  <div className="col-md-6" key={usr_adr.id}>
												<div id="address_area_42" className={usr_adr.set_default === 1 ? 'address-item address-block default_address' : 'address-item address-block'}>
												  <div title="Select this Address" className="address" onClick={() => updateShipToAddress(usr_adr)}>
													{showNewAddress==3 ? 
														(
														<>
														<p>{usr_adr.first_name} &nbsp;{usr_adr.last_name}</p>
														<p>{usr_adr.phone}</p>
														</>
														):null
													}
													<p>{usr_adr.address_1}</p>													
													<p>{usr_adr.city} {usr_adr.state}, {usr_adr.postcode}</p> 
												  </div>
												  <div className="linkswrapper">
													<a title="Edit Address" onClick={() => editaddress(usr_adr)} href="javascript:void(0);"><i className="fa fa-edit"></i></a>
													<a title="Delete Address" onClick={() => deleteUseraddress(usr_adr)} href="javascript:void(0);"><i className="fa fa-trash"></i></a>
												  </div>
												</div>	
											  </div>
											</>
										  ))
										  )
										  
										  }
										</div>															
									  </>
									) : null ) :
									(
									<>
									<div className="form-section section-register">
									<div className="form-row">	
									{showNewAddress==3 ? (
									<>
									 <div className="col-md-6">
										<div className="form-group">
										<label>First name*</label>
										<input type="text" className={`form-control ${addresseditvalidationError.first_nameError ? 'is-invalid' : ''}`} name="first_name" id="first_name" value={AddressEditForm.first_name} onChange={inputaddeditHandler} />
										</div>
									 </div>
									 <div className="col-md-6">
										<div className="form-group">
										<label>Last name*</label>
										<input type="text" className={`form-control ${addresseditvalidationError.last_nameError ? 'is-invalid' : ''}`} name="last_name" id="last_name" value={AddressEditForm.last_name} onChange={inputaddeditHandler} />
										</div>
									 </div>
									 <div className="col-md-6">
										<div className="form-group">
										<label>Mobile*</label>
										<InputMask 
											type='text' 
											className={`form-control ${addresseditvalidationError.phoneError ? 'is-invalid' : ''}`} name='phone' 
											id='phone' 
											value={AddressEditForm.phone} 
											onChange={inputaddeditHandler} 
											onKeyDown={handleKeyDown}
											mask="____ ___ ___" 
											replacement={{ _: /\d/ }} 
											placeholder="____ ___ ___" />										
										</div>
									 </div>
									 </>
									):null
									}
									
									
									 <div className="col-md-6">
										<div className="form-group">
										<label>Address*</label>
										<input type="text" className={`form-control ${addresseditvalidationError.addressError ? 'is-invalid' : ''}`} name="address_1" id="address_1" value={AddressEditForm.address_1} onChange={inputaddeditHandler} />
										</div>
									 </div>
									  <div className="col-md-6">
										<div className="form-group">
										<label>Suburb*</label>
										<input type="text" className={`form-control ${addresseditvalidationError.suburbError ? 'is-invalid' : ''}`} name="suburb" id="suburb" onChange={(e) => SearchAddeditSuburb(e.target.value)}  value={addeditsuburb}/>
										{SuburbsearchResults.length > 0 ? (
											<ul className="suburbList">
												{SuburbsearchResults.map((row) => (
													<li key={row.id} onClick={() => handleEditAddSuburb(row)}>
														{row.rowlocation} 
													</li>
												))}
											</ul>
										) : null}
										</div>
									 </div>
									  <div className="col-md-6">
										<div className="form-group">
										<label>State*</label>
										<select className={`form-control ${addresseditvalidationError.stateError ? 'is-invalid' : ''}`}  name="state" id="state" value={AddressEditForm.state} onChange={inputaddeditHandler}> 
										 <option value="">Select an option…</option>
										  <option value="ACT">Australian Capital Territory</option>
										  <option value="NSW">New South Wales</option>
										  <option value="NT">Northern Territory</option>
										  <option value="QLD">Queensland</option>
										  <option value="SA">South Australia</option>
										  <option value="TAS">Tasmania</option>
										  <option value="VIC">Victoria</option>
										  <option value="WA">Western Australia</option>
										</select>
										</div>
									 </div>
									  <div className="col-md-6">
										<div className="form-group">
										<label>Postcode*</label>
										<input type="text" className={`form-control ${addresseditvalidationError.postcodeError ? 'is-invalid' : ''}`} name="postcode" id="postcode" value={AddressEditForm.postcode} disabled />
										</div>
									 </div>
									 <div className="col-md-12 login-button text-right"><button className="login-btn" onClick={updateAddress}>Update Address</button>&nbsp;&nbsp;<button className="login-btn" onClick={cancelEditButton}>Cancel</button></div>
										</div>
										</div>		
									</>
									)									
									}									
								</TabPanel>	
								<TabPanel>
								<div className="form-section section-register">
								<div className="form-row">
								{showNewAddress==3 ? 
									(
									<>
									 <div className="col-md-6">
										<div className="form-group">
										<label>First name*</label>
										<input type="text" className={`form-control ${addressvalidationError.first_nameError ? 'is-invalid' : ''}`} name="first_name" id="first_name" value={newAddressform.first_name} onChange={inputaddressHandler} />
										</div>
									 </div>
									 <div className="col-md-6">
										<div className="form-group">
										<label>Last name*</label>
										<input type="text" className={`form-control ${addressvalidationError.last_nameError ? 'is-invalid' : ''}`} name="last_name" id="last_name" value={newAddressform.last_name} onChange={inputaddressHandler} />
										</div>
									 </div>
									 <div className="col-md-6">
										<div className="form-group">
										<label>Mobile*</label>
										<InputMask 
											type='text' 
											className={`form-control ${addressvalidationError.phoneError ? 'is-invalid' : ''}`} name='phone' 
											id='phone' 
											value={newAddressform.phone} 
											onChange={inputaddressHandler} 
											onKeyDown={handleKeyDown}
											mask="____ ___ ___" 
											replacement={{ _: /\d/ }} 
											placeholder="____ ___ ___" />										
										</div>
									 </div>
									 </>
									):null
									}	
								 <div className="col-md-6">
									<div className="form-group">
									<label>Address*</label>
									<input type="text" className={`form-control ${addressvalidationError.addressError ? 'is-invalid' : ''}`} name="newaddress_1" id="newaddress_1" value={newAddressform.newaddress_1} onChange={inputaddressHandler} />
									</div>
								 </div>
								  <div className="col-md-6">
									<div className="form-group">
									<label>Suburb*</label>
									<input type="text" className={`form-control ${addressvalidationError.suburbError ? 'is-invalid' : ''}`} name="newsuburb" id="newsuburb" onChange={(e) => SearchAddSuburb(e.target.value)}  value={addsuburb}/>
									{SuburbsearchResults.length > 0 ? (
										<ul className="suburbList">
											{SuburbsearchResults.map((row) => (
												<li key={row.id} onClick={() => handleNewsuburbSuburb(row)}>
													{row.rowlocation} 
												</li>
											))}
										</ul>
									) : null}
									</div>
								 </div>
								  <div className="col-md-6">
									<div className="form-group">
									<label>State*</label>
									<select className={`form-control ${addressvalidationError.stateError ? 'is-invalid' : ''}`}  name="newstate" id="newstate" value={newAddressform.newstate} onChange={inputaddressHandler}> 
									 <option value="">Select an option…</option>
									  <option value="ACT">Australian Capital Territory</option>
									  <option value="NSW">New South Wales</option>
									  <option value="NT">Northern Territory</option>
									  <option value="QLD">Queensland</option>
									  <option value="SA">South Australia</option>
									  <option value="TAS">Tasmania</option>
									  <option value="VIC">Victoria</option>
									  <option value="WA">Western Australia</option>
									</select>
									</div>
								 </div>
								  <div className="col-md-6">
									<div className="form-group">
									<label>Postcode*</label>
									<input type="text" className={`form-control ${addressvalidationError.postcodeError ? 'is-invalid' : ''}`} name="newpostcode" id="newpostcode" value={newAddressform.newpostcode} disabled />
									</div>
								 </div>
								 <div className="col-md-12 login-button text-right"><button className="login-btn" onClick={AddAddress}>Save Address</button></div>
									</div>
									</div>								
								</TabPanel>									
							</Tabs>							  
						  </div>
                    </div>
                </div>
            </div> 
            <div id="orderItemModalBox" className={odrmodalopen == '0' ? "modal fade cart-modal mc-popup orderItemModalBox" : "orderItemModalBox mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgb(40 40 40 / 61%)', display: odrmodalopen == '0' ? 'none' : 'block' }}
                data-backdrop="false">            
					  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="modal-title" id="orderCompleteModalTitle">Order Items.</h5>
							<button type="button" onClick={Odrclosebutton} className="close" data-dismiss="modal" aria-label="Close">
							  <span aria-hidden="true">×</span>
							</button>
						  </div>
						  <div className="modal-body">
							 <div id="orderitemdata" className=""><div className="table-responsive">
					<table className="table">
						<thead>
							<tr>
								<th>Product</th>
								<th>SKU</th>
								<th>Quantity</th>
								<th>Availability</th>
								<th>Total</th>
							</tr>
						</thead>
							
					<tbody>
					{orderdetails && orderdetails.products && orderdetails.products.length > 0 ? (
					  <>
						{orderdetails.products.map((Opro) => (
						  <tr className="main-sec-table collapsed" key={Opro.order_product_id}>
							<td className="first_product">{Opro.description}</td>
							<td className="product_sku">{Opro.hobson_part}</td>
							<td className="prodcut_quantity">{Opro.quantity}</td>
							<td className="prodcut_quantity"></td>
							<td className="second-product">{Opro.total}</td>
						  </tr>
						))}
					  </>
					) : null}
					</tbody>
					<tfoot>
						<tr>
							<td align="left" colspan="2">
								
											<h4>Shipping details</h4>
								<p>
									{orderdetails.name}<br />{orderdetails.company}<br />{orderdetails.address_1}<br />{orderdetails.city} {orderdetails.state} {orderdetails.postcode}<br />AU			</p>
								<p><strong>Mobile: </strong>{orderdetails.phone}</p>
								<p><strong>Email: </strong>{orderdetails.email}</p>
										</td>
							<td align="right" colspan="3">
											
								<h4>Payment Details</h4>
								<p>GST: ${orderdetails.tax} <br />
								 Order Total: {orderdetails.total}</p>
								<p>
									Payment using : Pay securely using your credit card<br /> TXN ID: VAR5HQup8VE4UTTUSkGRbaZKjVGZY			</p>
								 
								 
							</td>
						</tr>
					</tfoot>
					</table>
					</div>
					</div>
						  </div>
						  <div className="modal-footer">
							<a href="javascript:void(0);" onClick={Odrclosebutton} data-dismiss="modal" type="button" className="btn btn-primary">Close</a>
						  </div>
						</div>
					  </div>
					</div>					
				<div id="orderPoModalBox" className={PoOrderPopup == '0' ? "modal fade cart-modal mc-popup orderItemModalBox" : "orderItemModalBox mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgb(40 40 40 / 61%)', display: PoOrderPopup == '0' ? 'none' : 'block' }}
                data-backdrop="false">            
					  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="modal-title" id="orderCompleteModalTitle">Important note about your cart</h5>
							<button type="button" onClick={PoOrderPopupclosebutton} className="close" data-dismiss="modal" aria-label="Close">
							  <span aria-hidden="true">×</span>
							</button>
						  </div>
						  <div className="modal-body">
							   <div id="outstock_warning" className="text-justify d-none"><p>Some of the items in your cart are not available in the requested quantity. Items 'Out of stock' are marked in red and need to be removed. Items that are available for 'Backorder' are marked in orange. You can either reduce the quantity before placing the order or choose to order now and any items marked in orange will be set as a backorder. You can view the approximate backorder date of any item via the cart by clicking on the item and checking the availability status.</p></div>
							  <div className="ponumber">
								<label>Add PO number to your order (optional).</label>
								<input onChange={inputPoHandler} value={PoOrder} className="from-control" type="text" name="ponumber" id="ponumber" />
							  </div>
						</div>						 
						  <div className="modal-footer">
							<a href="javascript:;" onClick={PoOrderPopupclosebutton} data-dismiss="modal" type="button" className="btn btn-primary">RETURN TO CART</a>
							<a href="javascript:;" onClick={ () => PlaceOrder()} type="button" className="btn gotonowsquare btn-primary">PROCEED WITH ORDER</a>
						  </div>
						   </div>
						</div>
					  </div>
					
			<Footer />
		</>
  	)
}

export default Products;
